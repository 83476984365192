<template>
  <div class="list-item-res">
    <router-link
      class="list-item-res-a"
      v-for="i in res"
      :key="i.id"
      :to="{
        name: 'recorder',
        query: {
          bid: i.bookId,
          rid: i.id,
          memory: i.hasWordMemory,
          zoom: i.followIconName,
          rname: i.fileName,
          bname: i.bookName,
        },
      }"
      >{{ i.fileName }}</router-link
    >
  </div>
</template>

<script>
export default {
  name: "ress",
  props: {
    res: Array,
  },
};
</script>
<style></style>
