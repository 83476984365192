var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-item-res"},_vm._l((_vm.res),function(i){return _c('router-link',{key:i.id,staticClass:"list-item-res-a",attrs:{"to":{
      name: 'recorder',
      query: {
        bid: i.bookId,
        rid: i.id,
        memory: i.hasWordMemory,
        zoom: i.followIconName,
        rname: i.fileName,
        bname: i.bookName,
      },
    }}},[_vm._v(_vm._s(i.fileName))])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }