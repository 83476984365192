<template>
  <div class="list" v-if="list && list.length > 0">
    <div class="list-item" v-for="item in list" :key="item.id">
      <div class="list-item-title">
        <p>{{ item.folderName }}</p>
      </div>
      <ress
        v-if="item.resList && item.resList.length > 0"
        :res="item.resList"
      />
      <list
        v-if="item.children && item.children.length > 0"
        :list="item.children"
      ></list>
    </div>
  </div>
</template>

<script>
import Ress from "@/components/read/ress.vue";
export default {
  name: "list",
  components: { Ress },
  props: {
    list: Array,
  },
};
</script>

<style></style>
