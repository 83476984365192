<template>
  <div class="readindex">
    <template v-if="loading">
      <div class="primary-folder-header">
        <div class="primary-folder-header-tit">
          <span class="pos"></span>
          <p>{{ book.bookName }}</p>
          <!-- style="white-space: pre-wrap" -->
        </div>
        <div class="primary-folder-header-info">
          <span class="pos"></span>
          <div class="info">
            <span class="publisher" v-html="book.publisher"></span>
            <div class="small-box">
              <router-link
                :to="{
                  name: 'propose',
                  query: { bid: book.bookId, fid: fid, source: 2 },
                }"
                class="opinion"
              >
                <span class="icon"
                  ><img src="@/assets/images/op.png" alt=""
                /></span>
                <em>意见反馈</em>
              </router-link>
            </div>
          </div>
        </div>
        <div class="primary-folder-header-img">
          <img :src="cover" alt="" />
        </div>
      </div>
      <div class="primary-folder-list">
        <div class="primary-folder-list-tit">
          <span>跟读资源</span>
        </div>
        <div class="primary-folder-list-data">
          <div class="listMain" v-if="list && list.length > 0">
            <div class="list-item" v-for="(item, index) in list" :key="item.id">
              <div class="list-item-title" @click="tapList(index)">
                <p>{{ item.folderName }}</p>
                <van-icon :name="item.show ? 'arrow-up' : 'arrow-down'" />
              </div>
              <template v-if="item.show">
                <ress
                  v-if="item.resList && item.resList.length > 0"
                  :res="item.resList"
                />
                <list
                  v-if="item.children && item.children.length > 0"
                  :list="item.children"
                ></list>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
    <van-loading v-else type="spinner" color="#1989fa" />
  </div>
</template>

<script>
import { Dialog } from "vant";
import List from "@/components/read/list.vue";
import Ress from "@/components/read/ress.vue";
export default {
  name: "readindex",
  components: { List, Ress },
  data() {
    return {
      loading: false,
      fid: this.$route.query.fid == undefined ? "" : this.$route.query.fid,
      book: "",
      list: null,
      newList: null,
      cover: this.$store.state.iCdn + "2021XXNewBook/images/nobook.jpg",
    };
  },
  mounted() {
    this.getRead();
  },
  methods: {
    tapList(v) {
      for (let i = 0; i < this.list.length; i++) {
        if (i == v) {
          this.list[i].show = !this.list[i].show;
        }
        // else {
        //   this.list[i].show = false;
        // }
      }
    },
    getRead() {
      this.$api.mary
        .getrindex({ fid: this.fid })
        .then(({ tag, data, message }) => {
          if (tag == 1) {
            this.loading = true;
            this.book = data;
            this.list = this.forChildren(data.folderResList);
            console.log(this.list);
            if (this.book.coverImg != "") {
              this.cover = this.book.coverImg;
            }
          } else {
            Dialog.alert({
              title: "获取数据失败",
              message: message,
            }).then(() => {
              this.$router.go(0);
            });
            console.log(message);
          }
        });
    },
    forChildren(val) {
      for (let i = 0; i < val.length; i++) {
        if (val[i].children && val[i].children.length > 0) {
          this.forChildren(val[i].children);
        } else {
          if (val[i].resList && val[i].resList.length > 0) {
            for (let j = 0; j < val[i].resList.length; j++) {
              this.$set(val[i].resList[j], "bookId", this.book.bookId);
              this.$set(val[i].resList[j], "bookName", this.book.bookName);
            }
          }
        }
        this.$set(val[i], "show", i == 0 ? true : false);
      }
      return val;
    },
  },
};
</script>

<style lang="scss">
.primary-folder-list-data {
}
// .primary-folder-list-data
.listMain {
  flex-direction: column;
}
.list-item {
  width: 90%;
}
</style>
